import { Link, useLocation } from 'react-router-dom';
import { Building2, MapPin, RectangleEllipsis, SquareSlash } from 'lucide-react';
import { Button } from './ui/button';

const AppSidebar = () => {
  const location = useLocation();

  return (
    <div className="h-full fixed left-0 top-0 bottom-0 w-[280px] border-r px-2 pt-[56px] bg-white">
      <Button className="w-full justify-start" variant={location.pathname.startsWith('/company') ? 'secondary' : 'ghost'} asChild>
        <Link component={Link} to="/">
          <Building2 />
          Companies
        </Link>
      </Button>
      <Button className="w-full justify-start" variant={location.pathname === '/standard-fields' ? 'secondary' : 'ghost'} asChild>
        <Link component={Link} to="/standard-fields">
          <RectangleEllipsis />
          Standard Fields
        </Link>
      </Button>
      <Button className="w-full justify-start" variant={location.pathname === '/divisions' ? 'secondary' : 'ghost'} asChild>
        <Link component={Link} to="/divisions">
          <SquareSlash />
          Divisions
        </Link>
      </Button>
      <Button className="w-full justify-start" variant={location.pathname === '/msa-premium-defaults' ? 'secondary' : 'ghost'} asChild>
        <Link component={Link} to="/msa-premium-defaults">
          <MapPin />
          MSA Premiums
        </Link>
      </Button>
    </div>
  );
};

export default AppSidebar;
