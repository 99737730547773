import { useEffect, useRef } from 'react';
import { useDebouncedValue } from 'rooks';

const useDebouncedFormSubmit = (form, key, onSubmit, debounceRate = 250) => {
  const value = form.watch(key);
  const initialRef = useRef(value);
  const [debouncedValue] = useDebouncedValue(value, debounceRate);

  useEffect(() => {
    if (debouncedValue !== initialRef.current) {
      form.handleSubmit(onSubmit)();
      initialRef.current = debouncedValue;
    }
  }, [debouncedValue, form, onSubmit]);
};

export { useDebouncedFormSubmit };
