import { useParams } from 'react-router-dom';
import { useState } from 'react';
import moment from 'moment';
import { CirclePlus } from 'lucide-react';

import AddUserDialog from './AddUserDialog';

import { Error } from '@/components/ui/error';
import { Button } from '@/components/ui/button';
import { Loading } from '@/components/ui/loading';
import { useUsers } from '@/data/user';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';

const Users = () => {
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const params = useParams();
  const users = useUsers(params.companyUid);

  if (users.isLoading && !users.data) {
    return <Loading />;
  }

  if (users.error) {
    return <Error message="An error occurred loading company users" />;
  }

  const handleAddUserDialog = (e) => {
    e.preventDefault();
    setAddUserDialogOpen(true);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between">
        <h2 className="text-xl font-semibold">Users</h2>
        <Button variant="outline" size="sm" onClick={handleAddUserDialog}>
          <CirclePlus />
          Add Zebeler
        </Button>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>User</TableHead>
            <TableHead>Created</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Roles</TableHead>
            <TableHead>Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.data.query.map((userUid) => {
            const user = users.data.content[userUid];
            return (
              <TableRow key={userUid}>
                <TableCell className="font-medium">
                  {user.firstname} {user.lastname}
                </TableCell>
                <TableCell>{moment(user.create_ts).format('MMM Do, YYYY')}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>
                  <div className="flex items-center gap-1">
                    {user.roles.map((role) => (
                      <Badge key={role} variant="outline" className="capitalize">
                        {role}
                      </Badge>
                    ))}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    {user.deleted && <Badge variant="outline">Deleted</Badge>}
                    {user.disabled && <Badge variant="outline">Disabled</Badge>}
                    {!user.deleted && !user.disabled && <Badge variant="secondary">Active</Badge>}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {addUserDialogOpen && <AddUserDialog open={addUserDialogOpen} setOpen={setAddUserDialogOpen} />}
    </div>
  );
};

export default Users;
