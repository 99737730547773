import { useState } from 'react';
import { ChevronRight } from 'lucide-react';
import { stateKeys } from '../../../helpers/states';
import MsaPremiumDefaultForm from './MsaPremiumDefaultForm';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { cn } from '@/helpers/shadcn';
import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible';

const getPremiumRange = (msas) => {
  const premiums = msas.map((msa) => parseFloat(msa.value));
  const min = Math.min(...premiums);
  const max = Math.max(...premiums);
  if (min === max) {
    return `${min}%`;
  }
  return `${min}% - ${max}%`;
};

const State = ({ state }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (!stateKeys[state.stateKey]) {
    return null;
  }

  return (
    <>
      <TableRow onClick={handleToggleExpand}>
        <TableCell className="p-0">
          <div className="flex items-center gap-2">
            <Button variant="icon" aria-label="expand row" size="sm">
              <ChevronRight className={cn('transition-all', isExpanded && 'rotate-90')} />
            </Button>
            {stateKeys[state.stateKey]}
          </div>
        </TableCell>
        <TableCell>{state.msas.length}</TableCell>
        <TableCell>{getPremiumRange(state.msas)}</TableCell>
      </TableRow>
      <TableRow className={cn(!isExpanded && 'border-none', 'hover:bg-transparent')}>
        <TableCell colSpan={4} className="p-0">
          <Collapsible open={isExpanded}>
            <CollapsibleContent asChild>
              <Table>
                <TableHeader>
                  <TableRow className="!border-b">
                    <TableHead>MSA</TableHead>
                    <TableHead>Premium</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {state.msas.map((msaPremiumDefault) => (
                    <MsaPremiumDefaultForm key={msaPremiumDefault.uid} msaPremiumDefault={msaPremiumDefault} />
                  ))}
                </TableBody>
              </Table>
            </CollapsibleContent>
          </Collapsible>
        </TableCell>
      </TableRow>
    </>
  );
};

export default State;
