import axios from 'axios';
import useSWR from 'swr';

const useDivisions = (sectorType) => {
  const url = `/division?sectorType=${sectorType}`;
  const swr = useSWR(url, async (url) => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

export { useDivisions };
