import { useNavigate, Outlet } from 'react-router-dom';
import { useAuth, useUser } from './helpers/auth0';
import AppSidebar from '@/components/AppSidebar';
import AppHeader from '@/components/AppHeader';
import { Loading } from '@/components/ui/loading';

const App = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, loginWithRedirect, authStrategy } = useAuth();
  const user = useUser();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    if (authStrategy === 'zebel-api') {
      navigate('/login');
    } else {
      loginWithRedirect();
    }
    return null;
  }

  if (!user.roles.includes('zebeler')) {
    return 'You are not authorized to access this application.';
  }

  return (
    <>
      <AppHeader />
      <div className="min-h-[calc(100vh-48px)]">
        <AppSidebar />
        <main className="pl-[280px] max-w-[1400px] mx-auto">
          <div className="p-4">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
};

export default App;
