export const mutateListOnPost = (currentObjs, newObj) => ({
  content: Object.fromEntries([...Object.entries(currentObjs.content), [newObj.uid, newObj]]),
  query: [...currentObjs.query, newObj.uid]
});

export const mutateListOnPut = (currentObjs, updatedObj) => ({
  content: Object.fromEntries(Object.entries(currentObjs.content).map(([key, value]) => (key === updatedObj.uid ? [key, updatedObj] : [key, value]))),
  query: currentObjs.query
});

export const mutateListOnDelete = (currentObjs, deletedUid) => ({
  content: Object.fromEntries(Object.entries(currentObjs.content).filter(([key]) => key !== deletedUid)),
  query: currentObjs.query.filter((uid) => uid !== deletedUid)
});
