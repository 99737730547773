import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { LinkIcon, Loader2, MapPin, Phone } from 'lucide-react';
import { useCompany, usePutCompany } from '@/data/company';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { Error } from '@/components/ui/error';
import { Checkbox } from '@/components/ui/checkbox';
import { Separator } from '@/components/ui/separator';

const formSchema = z.object({
  company_name: z.string().min(1),
  company_description: z.string(),
  address: z.string(),
  phone: z.string(),
  website: z.string().url().optional().or(z.literal('')),
  is_market_data: z.boolean(),
  is_market_data_visible: z.boolean(),
  is_v1_estimate: z.boolean(),
  is_v2_estimate: z.boolean()
});

const EditCompany = () => {
  const params = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState('');
  const putCompany = usePutCompany();
  const company = useCompany(params.companyUid);
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      company_name: company.data.company_name,
      company_description: company.data.company_description,
      address: company.data.address,
      phone: company.data.phone,
      website: company.data.website,
      is_market_data: company.data.is_market_data,
      is_market_data_visible: company.data.is_market_data_visible,
      is_v1_estimate: company.data.is_v1_estimate,
      is_v2_estimate: company.data.is_v2_estimate
    }
  });

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    setApiError('');
    try {
      const updatedCompany = await putCompany(params.companyUid, values);
      company.mutate(updatedCompany, false);
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      setApiError(err.message);
    }
  };

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Company Info</h2>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="company_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Company Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="company_description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Company Description</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="grid grid-cols-3 gap-4">
            <FormField
              control={form.control}
              name="address"
              startIcon={<MapPin />}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              startIcon={<Phone />}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="website"
              startIcon={<LinkIcon />}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Website</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Separator />
          <div className="space-y-4">
            <div>
              <h3 className="font-medium text-lg">Market Data</h3>
              <p className="text-muted-foreground text-sm">Configure market data preferences for this company</p>
            </div>
            <FormField
              control={form.control}
              name="is_market_data"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl className="flex items-end">
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel>Include company data in aggregated market data</FormLabel>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="is_market_data_visible"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl className="flex items-end">
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel>Allow this company to view market data</FormLabel>
                </FormItem>
              )}
            />
          </div>
          <Separator />
          <div className="space-y-4">
            <div>
              <h3 className="font-medium text-lg">Visible Features</h3>
              <p className="text-muted-foreground text-sm">Configure what features this company can view within Zebel</p>
            </div>
            <FormField
              control={form.control}
              name="is_v1_estimate"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl className="flex items-end">
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel>Allow this company to view V1 Estimate Page</FormLabel>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="is_v2_estimate"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl className="flex items-end">
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel>Allow this company to view V2 Estimate Page</FormLabel>
                </FormItem>
              )}
            />
          </div>

          {apiError && <Error message={apiError} />}
          <div className="flex justify-end">
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting && <Loader2 className="animate-spin" />}
              Save Company
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default EditCompany;
