import axios from 'axios';
import useSWR from 'swr';

const useMsaPremiumDefaults = () => {
  const url = `/admin/msa-premium-default`;
  const swr = useSWR(url, async (url) => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const usePutMsaPremiumDefault = () => {
  const putMsaPremiumDefault = async (msaPremiumDefaultUid, payload) => {
    try {
      const url = `/admin/msa-premium-default/${msaPremiumDefaultUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      throw Error(error);
    }
  };
  return putMsaPremiumDefault;
};

export { useMsaPremiumDefaults, usePutMsaPremiumDefault };
