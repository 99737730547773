import { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { Button } from './ui/button';
import { useLogoutUser } from '@/helpers/auth0';

const AppHeader = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const logoutUser = useLogoutUser();

  const handleLogout = async () => {
    setIsLoggingOut(true);
    await logoutUser();
  };

  return (
    <header className="sticky top-0 z-50 w-full border-b  bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 h-[52px] px-4 flex items-center">
      <div className="flex items-center gap-2 flex-auto">
        <img src="/assets/logo.png" alt="Zebel Logo" className="w-[32px]" loading="lazy" />
        <div className="font-semibold">Zebel Admin</div>
      </div>
      <Button onClick={handleLogout} variant="outline" className="flex items-center gap-1" disabled={isLoggingOut}>
        {isLoggingOut && <Loader2 className="animate-spin" />}
        Log Out
      </Button>
    </header>
  );
};

export default AppHeader;
