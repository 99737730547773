import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Percent } from 'lucide-react';
import { usePutMsaPremiumDefault, useMsaPremiumDefaults } from '@/data/msaPremiumDefaults';

import { Input } from '@/components/ui/input';
import { TableRow, TableCell } from '@/components/ui/table';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useDebouncedFormSubmit } from '@/helpers/form';
import { mutateListOnPut } from '@/helpers/swr';
const formSchema = z.object({
  value: z.coerce.number()
});

const MsaPremiumDefaultForm = ({ msaPremiumDefault }) => {
  const putMsaPremiumDefault = usePutMsaPremiumDefault();
  const form = useForm({ resolver: zodResolver(formSchema), defaultValues: { value: msaPremiumDefault.value || '' } });
  const msaPremiumDefaults = useMsaPremiumDefaults();

  const onSubmit = async (values) => {
    await msaPremiumDefaults.mutate(putMsaPremiumDefault(msaPremiumDefault.uid, values), {
      optimisticData: (current) => mutateListOnPut(current, { ...msaPremiumDefault, ...values }),
      rollbackOnError: true,
      populateCache: false,
      revalidate: false
    });
  };

  useDebouncedFormSubmit(form, 'value', onSubmit, 500);

  return (
    <TableRow>
      <TableCell>{msaPremiumDefault.msa.msa_name}</TableCell>
      <TableCell className="py-1 w-[100px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="value"
              endIcon={<Percent />}
              render={({ field }) => (
                <FormItem className="w-[140px]">
                  <FormLabel className="sr-only">Premium</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      </TableCell>
    </TableRow>
  );
};

export default MsaPremiumDefaultForm;
