import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { CirclePlus } from 'lucide-react';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Error } from '@/components/ui/error';
import { Loading } from '@/components/ui/loading';
import { Button } from '@/components/ui/button';
import { useCompanies } from '@/data/company';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';

const List = () => {
  const companies = useCompanies();

  if (companies.isLoading && !companies.data) {
    return <Loading />;
  }

  if (companies.error) {
    return <Error message="An error occurred loading companies" />;
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle>Companies</CardTitle>
          <Button asChild>
            <Link to="/company/add">
              <CirclePlus />
              Add Company
            </Link>
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Logo</TableHead>
              <TableHead>Company</TableHead>
              <TableHead>Created</TableHead>
              <TableHead>Status</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {companies.data.query.map((companyUid) => {
              const company = companies.data.content[companyUid];
              return (
                <TableRow key={companyUid}>
                  <TableCell className="w-[60px] p-0">
                    <Link to={`/company/${company.uid}`} className="p-4 block">
                      <div className="border rounded w-[60px] h-[40px] bg-background flex items-center justify-center p-1">
                        {company.logo_url && <img src={company.logo_url} alt={company.company_name} className="max-w-[50px] max-h-[30px] object-cover" loading="lazy" />}
                        {!company.logo_url && <div className="text-[8px] uppercase text-secondary">No Logo</div>}
                      </div>
                    </Link>
                  </TableCell>
                  <TableCell className="font-medium p-0">
                    <Link to={`/company/${company.uid}`} className="p-4 block">
                      {company.company_name}
                    </Link>
                  </TableCell>

                  <TableCell className="p-0">
                    <Link to={`/company/${company.uid}`} className="p-4 block">
                      {moment(company.create_ts).format('MMM Do, YYYY')}
                    </Link>
                  </TableCell>
                  <TableCell className="p-0">
                    <Link to={`/company/${company.uid}`} className="p-4 block">
                      <div className="flex gap-2">
                        {company.deleted && <Badge variant="outline">Deleted</Badge>}
                        {company.disabled && <Badge variant="outline">Disabled</Badge>}
                        {!company.deleted && !company.disabled && <Badge variant="secondary">Active</Badge>}
                      </div>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default List;
