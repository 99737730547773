import axios from 'axios';
import useSWR from 'swr';

const useUsers = (companyUid) => {
  const url = `/admin/user?companyUid=${companyUid}`;
  const swr = useSWR(url, async (url) => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const usePostZebelerUser = () => {
  const postZebelerUser = async (payload) => {
    try {
      const url = `/admin/user/zebeler`;
      const result = await axios.post(url, payload);
      return result.data;
    } catch (error) {
      throw Error(error);
    }
  };
  return postZebelerUser;
};

export { useUsers, usePostZebelerUser };
