import { useSearchParams } from 'react-router-dom';
import Divisions from './components/Divisions';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { projectTypes } from '@/helpers/constants';

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Label } from '@/components/ui/label';

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sectorType = searchParams.get('sectorType') || 'multifamily';

  const handleChangeTab = (value) => {
    setSearchParams({ sectorType: value });
  };
  return (
    <Card>
      <CardHeader>
        <CardTitle>Divisions</CardTitle>
      </CardHeader>
      <CardContent>
        <Label>Sector Type</Label>
        <Select value={sectorType} onValueChange={handleChangeTab}>
          <SelectTrigger className="max-w-[280px] mb-4">
            <SelectValue placeholder="Theme" />
          </SelectTrigger>
          <SelectContent>
            {projectTypes.map((projectType) => (
              <SelectItem key={projectType.value} value={projectType.value}>
                {projectType.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Divisions />
      </CardContent>
    </Card>
  );
};

export default List;
