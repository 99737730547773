import State from './State';
import { useMsaPremiumDefaults } from '@/data/msaPremiumDefaults';
import { Loading } from '@/components/ui/loading';
import { Error } from '@/components/ui/error';
import { Table, TableBody, TableHead, TableHeader, TableRow } from '@/components/ui/table';

const getMsasByStates = (data) => {
  const states = {};
  data.query.forEach((msaPremiumDefaultUid) => {
    const msaPremiumDefault = data.content[msaPremiumDefaultUid];
    if (!states[msaPremiumDefault.msa.state]) {
      states[msaPremiumDefault.msa.state] = [];
    }
    states[msaPremiumDefault.msa.state].push(msaPremiumDefault);
  });
  return Object.keys(states)
    .sort((a, b) => a.localeCompare(b))
    .map((stateKey) => ({ stateKey, msas: states[stateKey].sort((a, b) => a.msa.msa_name.localeCompare(b.msa.msa_name)) }));
};

const List = () => {
  const msaPremiumDefaults = useMsaPremiumDefaults();

  if (msaPremiumDefaults.isLoading) {
    return <Loading />;
  }

  if (msaPremiumDefaults.error) {
    return <Error message="An error occurred loading data." />;
  }

  const states = getMsasByStates(msaPremiumDefaults.data);

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>State</TableHead>
          <TableHead># of MSAs</TableHead>
          <TableHead>Premium Range</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {states.map((state) => (
          <State key={state.state} state={state} />
        ))}
      </TableBody>
    </Table>
  );
};

export default List;
