import { useSearchParams } from 'react-router-dom';
import { Error } from '@/components/ui/error';
import { Loading } from '@/components/ui/loading';
import { useDivisions } from '@/data/division';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';

const Divisions = () => {
  const [searchParams] = useSearchParams();
  const sectorType = searchParams.get('sectorType') || 'multifamily';
  const divisions = useDivisions(sectorType);

  if (divisions.isLoading && !divisions.data) {
    return <Loading />;
  }

  if (divisions.error) {
    return <Error message="An error occurred loading divisions" />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">Code</TableHead>
          <TableHead>Division</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {divisions.data.query.map((divisionUid) => {
          const division = divisions.data.content[divisionUid];
          return (
            <TableRow key={divisionUid}>
              <TableCell className="font-medium">{division.division_code}</TableCell>
              <TableCell>{division.division_name}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
export default Divisions;
