import axios from 'axios';
import useSWR from 'swr';

const useCompanies = () => {
  const url = `/admin/company`;
  const swr = useSWR(url, async (url) => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const useCompany = (companyUid) => {
  const url = `/admin/company/${companyUid}`;
  const swr = useSWR(url, async (url) => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const usePutCompany = () => {
  const putCompany = async (companyUid, payload) => {
    try {
      const url = `/admin/company/${companyUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      throw Error(error);
    }
  };
  return putCompany;
};

const usePostCompany = () => {
  const postCompany = async (payload) => {
    try {
      const url = `/admin/company`;
      const result = await axios.post(url, payload);
      return result.data;
    } catch (error) {
      throw Error(error);
    }
  };
  return postCompany;
};

export { useCompanies, useCompany, usePutCompany, usePostCompany };
