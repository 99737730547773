import LoginForm from './components/LoginForm';

const LoginPage = () => {
  return (
    <div className="flex items-center justify-center p-4 h-screen w-screen">
      <LoginForm />
    </div>
  );
};

export default LoginPage;
