import { Loader2 } from 'lucide-react';

const Loading = ({ height = 150 }) => {
  return (
    <div className="flex items-center justify-center" style={{ height: `${height}px` }}>
      <Loader2 className="animate-spin" />
    </div>
  );
};

export { Loading };
