import List from './components/List';
import { Card, CardDescription, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
const MsaPremiumDefaultsPage = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>MSA Premium Defaults</CardTitle>
        <CardDescription>
          MSA premium defaults are the default values for the msa premium company tables. When a company is created, these values will be copied into their company settings as a
          starting point.
        </CardDescription>
      </CardHeader>

      <CardContent>
        <List />
      </CardContent>
    </Card>
  );
};

export default MsaPremiumDefaultsPage;
