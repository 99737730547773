import { Link, Outlet, useHref, useParams } from 'react-router-dom';

import { Building2, Users } from 'lucide-react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { useCompany } from '@/data/company';
import { Loading } from '@/components/ui/loading';
import { Error } from '@/components/ui/error';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';

const Company = () => {
  const params = useParams();
  const company = useCompany(params.companyUid);
  const pathname = useHref();

  if (company.isLoading && !company.data) {
    return <Loading />;
  }

  if (company.error) {
    return <Error message="An error occurred loading company" />;
  }

  return (
    <>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/company">Companies</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{company.data.company_name}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Card>
        <CardHeader className="flex flex-row items-center justify-start gap-4">
          <div className="border rounded w-[80px] h-[60px] bg-background flex items-center justify-center p-1">
            {company.data.logo_url && <img src={company.data.logo_url} alt={company.data.company_name} className="max-w-[70px] max-h-[60px] object-cover" loading="lazy" />}
            {!company.data.logo_url && <div className="text-[8px] uppercase text-secondary">No Logo</div>}
          </div>
          <div>
            <CardTitle>{company.data.company_name}</CardTitle>
            <CardDescription>{company.data.company_description}</CardDescription>
          </div>
        </CardHeader>
        <CardContent>
          <div className="flex items-center gap-2">
            <Button asChild variant={pathname === `/company/${params.companyUid}` ? 'secondary' : 'ghost'}>
              <Link to={`/company/${params.companyUid}`}>
                <Building2 />
                Company
              </Link>
            </Button>
            <Button asChild variant={pathname === `/company/${params.companyUid}/users` ? 'secondary' : 'ghost'}>
              <Link to={`/company/${params.companyUid}/users`}>
                <Users />
                Users
              </Link>
            </Button>
          </div>

          <div className="pt-4">
            <Outlet />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default Company;
