const projectTypes = [
  {
    label: 'Mixed-Use Multifamily',
    value: 'multifamily'
  },
  {
    label: 'Student Housing',
    value: 'student_housing'
  },
  {
    label: 'Senior Housing',
    value: 'senior_housing'
  },
  {
    label: 'Single Family',
    value: 'singlefamily'
  },
  {
    label: 'Industrial',
    value: 'industrial'
  },
  {
    label: 'Hospital',
    value: 'hospital'
  },
  {
    label: 'School',
    value: 'school'
  },
  {
    label: 'Storage',
    value: 'storage'
  },
  {
    label: 'Life Sciences',
    value: 'life_sciences'
  },
  {
    label: 'Office',
    value: 'office'
  },
  {
    label: 'Hotel',
    value: 'hotel'
  },
  {
    label: 'Religion',
    value: 'religion'
  },
  {
    label: 'Various',
    value: 'various'
  }
];

export { projectTypes };
